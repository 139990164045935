<template>
    <div class="start-page">
        <div class="row no-gutters">
            <div class="col-12 col-lg-6">
                <router-link to="/">
                    <div class="block" @click="setRole('supplier')">
                        <h2>
                            Поставщик
                        </h2>
                        <img src="@/assets/img/leftLink.svg" alt="">
                    </div>
                </router-link>
            </div>
            <div class="col-12 col-lg-6">
                <router-link to="/">
                    <div class="block" @click="setRole('customer')">
                        <h2>
                            Заказчик / Аудитор
                        </h2>
                        <img src="@/assets/img/rightLink.svg" alt="">
                    </div>
                </router-link>
            </div>
        </div>
        <div class="center-icon d-none d-lg-block">
            <img src="@/assets/img/centerIcon.svg" alt="">
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
    export default {
        name: 'Starterpage',
        computed: {
            ...mapGetters([
                'userRole'
            ]),
        },
        mounted() {
            this.onShow();
        },
        methods: {
            onShow() {
                if (this.userRole !== null) {
                    this.$router.push('/')
                }
            },
            setRole(role) {
                this.$store.commit('SET_ROLE', role);
            }
        }
    }
</script>

<style lang="scss" scoped>
.start-page {
    width: 100vw;
    height: 100vh;
    position: fixed;
}
.row {
    height: 100%;
}
.center-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.center-icon img {
    max-width: 258px;
    width: 100%;
    height: 258px;
}
.block {
    width: 100%;
    height: 100%;
    background:#F3F1FA;
    transition: all .2s;
}
.block:hover {
    background: #6851C3;
    h2 {
        color: #fff;
    }
}
.block img {
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.block h2 {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #595959;
}
@media (max-width: 992px) {
    .block h2 {
        top: 10%;
    }
    .block img {
        max-width: 50%;
    }
}
</style>